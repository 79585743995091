import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image, Avatar, Popconfirm, Pagination } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, UserOutlined,SearchOutlined,ReloadOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            selectedRowKeys: [],
            list: [],
            tableloading: false,
            count: 0,
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {},
                oldcondition: {},
                list: [],
                tableloading: false,
                count: 0,
                loading: false
            })
        }
    }

    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.id = that.props.id
        fetch('/Message/company', {
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                that.setState({
                    count: data.count,
                    list: data.datalist,
                    tableloading: false,
                    status_react: data.status_react ? data.status_react : []
                })
            })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
        
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    delSel(edit, e) {
        let that = this
        fetch('/Message/allsavecompany', {
            method: "POST",
            body: JSON.stringify({
                'choose': this.state.selectedRowKeys,
                'edit': edit
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })

            })
    }
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '公司名称',
                dataIndex: 'cname',
                key: 'cname',
            },
            {
                title: '已读时间',
                dataIndex: 'readtime',
                key: 'readtime',
                width: 120,
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll:true,
            preserveSelectedRowKeys: false,
            // renderCell: function(checked, record, index, originNode){
            //     if(record.status!=1){
            //         return originNode
            //     }else{
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{this.props.num ? this.props.num : 0}</a>
                <Modal title="已读人数" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Row justify="space-between" style={{ marginBottom: 20 }}>
                        <Space wrap>
                            <Select
                                className='select' style={{ width: 160 }} placeholder="状态" value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} allowClear>
                                {this.arrsel(this.state.status_react)}
                            </Select>
                            <Button type="primary" icon={<SearchOutlined />} onClick={this.getdata.bind(this, 1)}>查询</Button>
                            <Button type="primary" icon={<ReloadOutlined />} className='huise' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                    </Row>
                    <Table2
                        bordered
                        columns={columns}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        rowSelection={rowSelection}
                        // scroll={{x:2200}}
                        className='table'
                    />
                    <div className='page page3'>
                        <div className='l'>
                            <Space wrap>
                                {/* <Checkbox onChange={this.qxbox.bind(this)} checked={this.state.qx ? this.state.qx : false}>全选</Checkbox> */}
                                {hasSelected ?
                                    <>
                                        <Popconfirm
                                            title="确认删除?"
                                            onConfirm={this.delSel.bind(this, 1)}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button type="primary" icon={<DeleteOutlined />} danger>删除</Button>
                                        </Popconfirm>
                                    </>
                                    : null}
                            </Space>
                        </div>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}