import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Message/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    that.setState({
                        mb: data.mb
                    })
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Message/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            // console.log(info)
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }
            console.log(name, info)
            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.url, [name + '_name']: info.file.response.name })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        console.log(fjlist)
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }

    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, content: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EditOutlined /> 编辑</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='huangse' icon={<PlusOutlined />}>发布消息</Button>
                }
                <Modal title="详情" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={[20, 0]} justify="start">
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="标题"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.title ? this.state.condition.title : null} onChange={this.onChange.bind(this, 'title')} autoComplete="off" disabled={this.props.id?true:false} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="内容"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.content ? this.state.condition.content : null} onChange={this.onChange.bind(this, 'content')} autoComplete="off" rows={6} disabled={this.props.id?true:false} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label={<Space><span>指定企业</span><a href={this.state.mb ? this.state.mb : 'javascript:void(0)'} target='_blank'>下载模板</a></Space>}
                                    tooltip="不上传默认发送给所有企业"
                                >
                                    <Row gutter={[0, 15]}>
                                        <Col span={24}>
                                            <Dragger
                                                showUploadList={false}
                                                action="/Ajax/uploadexcel"
                                                onChange={this.picChange.bind(this, 'file')}
                                                multiple={false}
                                                data={{
                                                    // ext:['pdf','mp4']
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                                <p className="ant-upload-hint">
                                                    {"支持xls,xlsx格式"}
                                                </p>
                                            </Dragger>
                                        </Col>
                                        {this.state.condition.file ?
                                            <Col span={24}>
                                                <a href={this.state.condition.file} target="_blank" download={this.state.condition.filename}>{this.state.condition.file_name}</a>
                                            </Col>
                                            : null}
                                    </Row>

                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}