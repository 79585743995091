import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Avatar, Popconfirm, DatePicker, Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined, UserOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, DeleteOutlined, InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
const { Option } = Select;
const { Dragger } = Upload;
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {},
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.props.NAV_CHANGE('/xtsz', ['首页', '系统设置'])
        this.getdata(1)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata = () => {
        let that = this
        fetch('/Setting/index', {
            // post提交
            method: "POST",
            body: JSON.stringify({})
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                data.data.editorState = BraftEditor.createEditorState(data.data.about)
                that.setState({
                    condition:data.data
                })

            })
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Setting/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                } else {
                    message.error(data.msg)
                }
                that.setState({
                    loading: false
                })
            })
    }
    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, about: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.url })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    render() {
        //多媒体上传
        const myUploadFn = (param) => {

            const serverURL = '/Ajax/uploads'
            const xhr = new XMLHttpRequest
            const fd = new FormData()

            const successFn = (response) => {
                // 假设服务端直接返回文件上传后的地址
                // 上传成功后调用param.success并传入上传后的文件地址
                let reg = /["\\]/g
                var url = xhr.responseText.replace(reg, "");
                param.success({
                    // url: xhr.responseText,
                    url: url,
                    meta: {
                        // id: 'xxx',
                        // title: 'xxx',
                        // alt: 'xxx',
                        loop: false, // 指定音视频是否循环播放
                        autoPlay: false, // 指定音视频是否自动播放
                        controls: true, // 指定音视频是否显示控制栏
                        poster: '', // 指定视频播放器的封面
                    }
                })
            }

            const progressFn = (event) => {
                // 上传进度发生变化时调用param.progress
                param.progress(event.loaded / event.total * 100)
            }

            const errorFn = (response) => {
                // 上传发生错误时调用param.error
                param.error({
                    msg: 'unable to upload.'
                })
            }

            xhr.upload.addEventListener("progress", progressFn, false)
            xhr.addEventListener("load", successFn, false)
            xhr.addEventListener("error", errorFn, false)
            xhr.addEventListener("abort", errorFn, false)

            fd.append('file', param.file)
            xhr.open('POST', serverURL, true)
            xhr.send(fd)

        }
        const controls = [
            'undo', 'redo', 'separator',
            'font-size', 'line-height', 'letter-spacing', 'separator',
            'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
            'superscript', 'subscript', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
            'headings', 'list-ul', 'list-ol', 'code', 'blockquote', 'table', 'media', 'separator',
            'hr', 'link', 'separator', 'separator',
            'clear'
        ]
        return (
            <>
                <Form
                    layout="vertical"
                    // labelCol={{ span: 3 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    style={{ width: 800 }}
                >
                    <Row gutter={[20, 0]} justify="start">
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                label="小程序名称"
                            >
                                <Input placeholder="请输入" value={this.state.condition.webname ? this.state.condition.webname : null} onChange={this.onChange.bind(this, 'webname')} autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                label="域名"
                            >
                                <Input placeholder="请输入" value={this.state.condition.weburl ? this.state.condition.weburl : null} onChange={this.onChange.bind(this, 'weburl')} autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                label="关于我们"
                            >
                                <div className="editor-wrapper" style={{ border: '1px solid #bbb' }}>
                                    <BraftEditor
                                        value={this.state.condition.editorState}
                                        onChange={this.editorChange}
                                        controls={controls}
                                        media={{ uploadFn: myUploadFn }}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                label="交流二维码设置"
                                tooltip="图片尺寸500*500"
                            >
                                <Upload
                                    showUploadList={false}
                                    listType="picture-card"
                                    action="/Ajax/uploadimages"
                                    onChange={this.picChange.bind(this, 'jlqewm')}
                                    multiple={false}
                                    style={{ width: 102 }}
                                    data={{ w: 500, h: 500 }}
                                >
                                    {this.state.condition.jlqewm ? (
                                        <img
                                            src={this.state.condition.jlqewm}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) :
                                        <PlusOutlined />
                                    }
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="start">
                        <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>提交</Button>
                    </Row>
                </Form>
            </>
        )
    }
}
