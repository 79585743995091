import logo from './logo.svg';
import React from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import './index.css';
// 404

import Error from './Error/error404.js';
import LoginAction from './store/action/LoginAction.js';

import HomeAction from './store/action/HomeAction.js';

import ShouyeAction from './store/action/ShouyeAction.js';

import QyglAction from './store/action/QyglAction.js';
import XqglAction from './store/action/XqglAction.js';
import DtglAction from './store/action/DtglAction.js';
import HdglAction from './store/action/HdglAction.js';
import PlglAction from './store/action/PlglAction.js';
import XxglAction from './store/action/XxglAction.js';
import WxyhglAction from './store/action/WxyhglAction.js';
import CzjlAction from './store/action/CzjlAction.js';
import SjbfAction from './store/action/SjbfAction.js';
import XtszAction from './store/action/XtszAction.js';


import UserAction from './store/action/UserAction.js';


import store from "./store/store.js";
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            route: [],
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data)
        })
    }
    componentDidMount() {

    }
    Shouye() {
        let route = [
            <Route exact path="/" component={ShouyeAction} />,
        ]
        return route
    }
    Qygl() {
        let route = [
            <Route exact path="/qygl" component={QyglAction} />,
        ]
        return route
    }
    Xqgl() {
        let route = [
            <Route exact path="/xqgl" component={XqglAction} />,
        ]
        return route
    }
    Dtgl() {
        let route = [
            <Route exact path="/dtgl" component={DtglAction} />,
        ]
        return route
    }
    Hdgl() {
        let route = [
            <Route exact path="/hdgl" component={HdglAction} />,
        ]
        return route
    }
    Plgl() {
        let route = [
            <Route exact path="/plgl" component={PlglAction} />,
        ]
        return route
    }
    Xxgl() {
        let route = [
            <Route exact path="/xxgl" component={XxglAction} />,
        ]
        return route
    }
    Wxyhgl() {
        let route = [
            <Route exact path="/wxyhgl" component={WxyhglAction} />,
        ]
        return route
    }
    User() {
        let route = [
            <Route exact path="/htzhgl" component={UserAction} />,
        ]
        return route
    }
    Czjl() {
        let route = [
            <Route exact path="/czjl" component={CzjlAction} />,
        ]
        return route
    }
    Sjbf() {
        let route = [
            <Route exact path="/Sjbf" component={SjbfAction} />,
        ]
        return route
    }
    Xtsz() {
        let route = [
            <Route exact path="/xtsz" component={XtszAction} />,
        ]
        return route
    }

    render() {
        let isLogin = store.getState().data.isLogin ? store.getState().data.isLogin : false
        return (
            <ConfigProvider locale={zhCN}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login" component={LoginAction} />
                        {!isLogin ? <Redirect to="/login" /> :
                            <HomeAction>
                                <Switch>
                                    {this.Shouye()}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('1')?this.Qygl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('2')?this.Xqgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('3')?this.Dtgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('4')?this.Hdgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('5')?this.Plgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('6')?this.Xxgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('7')?this.Wxyhgl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('8')?this.User():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('9')?this.Czjl():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('10')?this.Sjbf():null):null}
                                    {store.getState().data.info.right?(store.getState().data.info.right.includes('11')?this.Xtsz():null):null}
                                    <Route path="*" component={Error} />
                                </Switch>
                            </HomeAction>
                        }
                    </Switch>
                </HashRouter>
            </ConfigProvider>
        );
    }
}

export default App;
