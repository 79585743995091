import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined,EyeOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import ReactJson from 'react-json-view'
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Record/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    render() {
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EyeOutlined /> 查看详情</a>
                <Modal title="详情" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={[20, 0]} justify="start">
                        <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="用户名"
                                >
                                    <Input placeholder="请输入"  value={this.state.condition.username ? this.state.condition.username : ''} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="姓名"
                                >
                                    <Input placeholder="请输入"  value={this.state.condition.name ? this.state.condition.name : ''} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="操作栏目"
                                >
                                    <Input placeholder="请输入"  value={this.state.condition.lanmu ? this.state.condition.lanmu : ''} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="账号类型"
                                >
                                    <Input placeholder="请输入"  value={this.state.condition.sf ? this.state.condition.sf : ''} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="请求方法"
                                >
                                    <Input placeholder="请输入"  value={this.state.condition.url ? this.state.condition.url : ''} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="请求参数"
                                >
                                    {/* <TextArea rows={6} placeholder="请输入" value={this.state.condition.content ? this.state.condition.content : null} disabled={true} /> */}
                                    <ReactJson name={false} displayDataTypes={false} src={this.state.condition.content ? this.state.condition.content : null} />
                                </Form.Item>
                            </Col>
                        </Row>

                     
                    </Form>
                </Modal>
            </>
        )
    }
}