import { connect } from 'react-redux';

//=====引入组件=====
import Login from '../../login/login.js'

//=====react-redux 封装组件=====

// 哪些 Redux 全局的 state 是我们组件想要通过 props 获取的？
function mapStateToProps(state) {
    return {
        isLogin: state.data.isLogin,
        info: state.data.info,
        // isLogin: true
    }
}

// 哪些 action 创建函数是我们想要通过 props 获取的？
function mapDispatchToProps(dispatch) {
	return {
        GOLOGIN: function (info,history){
			setTimeout(function(){
                dispatch({ 
                    type:"GO_LOGIN",
                    info:info,
                })
                // if (info.type==1){
                    history.push("/")
                // }else{
                //     history.push("/userview")
                // }
			},1000)
			
        },
        OUT_LOGIN: function () {
            setTimeout(function () {
                dispatch({ type: "OUT_LOGIN" })
                // history.push("/login")
                // history.push("/")
            }, 0)

        },
        SET_WEBINFO: function(webinfo){
			let action = {
				type: "SET_WEBINFO",
				webinfo: webinfo,
			}
			// console.log(action);
			dispatch(action);
		},
	};
}

//封装传递state和dispatch
var LoginAction = connect(mapStateToProps,mapDispatchToProps)(Login);

export default LoginAction