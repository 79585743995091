import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, DatePicker, Tooltip } from 'antd';
import store from "../store/store.js";
import { BarsOutlined, SearchOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Edit from '../xqgl/edit.js';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.tzggqx)
        })
    }
    componentDidMount() {

    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    arrtag(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Tag color='green' key={index}>{item}</Tag>
            )
        }
    }
    onRangeChange(e, dates, dateStrings) {
        console.log(dates, dateStrings)
        let data = Object.assign({}, this.state.newcondition, { stime: dateStrings[0], etime: dateStrings[1] })
        this.setState({
            newcondition: data
        })
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.id = this.props.id
        fetch('/Xuqiu/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.datalist,
                    status_react: data.status_react?data.status_react:[],
                    tags_react: data.tags_react?data.tags_react:[],
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    delSel() {
        let that = this
        // fetch('/Gonggao/delSel', {
        //     method: "POST",
        //     body: JSON.stringify({
        //       'choose': this.state.selectedRowKeys,
        //     })
        //   })
        //     .then(res => res.json())
        //     .then(data => {
        //         // console.log(data)
        //         this.pagechange(1, this.state.oldcondition.pagesize)
        //         that.setState({
        //             selectedRowKeys:[]
        //         })
        //     })
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata(1)
        } else {
            that.setState({
                newcondition: {},
                loading: false
            })
        }
    }
    render() {
        const columns = [
            {
                title: '需求标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '需求标签',
                dataIndex: 'tagsname',
                key: 'tagsname',
                width: 300,
                render: (row, record) => {
                    return (
                        <>
                            <Space wrap>
                                {this.arrtag(row)}
                            </Space>
                        </>
                    )
                },
            },

            {
                title: '需求状态',
                dataIndex: 'status',
                key: 'status',
                width: 120,
                render: (row, record) => {
                    return (
                        <>
                            {row == 0 ? <Tag>{record.status_text}</Tag> : null}
                            {row == 1 ? <Tag color='green'>{record.status_text}</Tag> : null}
                            {row == 2 ? <Tag color='red'>{record.status_text}</Tag> : null}
                        </>
                    )
                },
            },
            {
                title: '发布时间',
                dataIndex: 'addtime',
                key: 'addtime',
                width: 150,
            },
            
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            preserveSelectedRowKeys: false,
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{this.props.num ? this.props.num : 0}</a>
                <Modal title="发布需求数" destroyOnClose={true} width={1200} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='sellist'>
                        <Row justify="space-between">
                            <Space wrap>
                                <Input placeholder="关键词" value={this.state.newcondition.keywords ? this.state.newcondition.keywords : null} onChange={this.onChange.bind(this, 'keywords')} autoComplete="off" style={{ width: 250 }} />
                                <Select style={{ width: 100 }} placeholder="状态" value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} allowClear>
                                    {this.arrsel(this.state.status_react)}
                                </Select>
                                <Button type="primary" icon={<SearchOutlined />} onClick={this.getdata.bind(this, 1)}>查询</Button>
                                <Button type="primary" icon={<ReloadOutlined />} className='huise' onClick={this.reset.bind(this)}>重置</Button>
                            </Space>
                        </Row>
                    </div>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={this.state.list}
                        pagination={false}
                        loading={tableloading}
                        // rowSelection={rowSelection}
                        // scroll={{x:2200}}
                        className='table'
                    />
                    <div className='page'>
                        <div className='l'>
                            {/* <Space wrap>
                                <Checkbox onChange={this.qxbox.bind(this)} checked={this.state.qx ? this.state.qx : false}>全选</Checkbox>
                                
                                {hasSelected ?
                                    <>
                                        <Popconfirm
                                            title="确认删除?"
                                            onConfirm={this.delSel.bind(this)}
                                            okText="确认"
                                            cancelText="取消"
                                        >
                                            <Button type="primary" icon={<DeleteOutlined />} danger>删除</Button>
                                        </Popconfirm>
                                        <Button type="primary" className='but green'>通过</Button>
                                        <Button type="primary" className='but yellow'>拒绝</Button>
                                    </>
                                    : null}
                            </Space> */}
                        </div>
                        <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                    </div>
                </Modal>
            </>
        )
    }
}