import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            status_react: [
                { id: 1, name: '通过' },
                { id: 2, name: '拒绝' }
            ],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Huodong/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    that.setState({
                        company_react: data.company_react ? data.company_react : [],
                    })
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    fmt: []
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Huodong/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        console.log(fjlist)
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    mapClick = (e) => {
        // console.log(e.latlng)
        let that = this
        console.log(that.state.condition.markers)
        if (that.state.isbz) {
            let arr = that.state.condition.markers.concat()
            arr[0] = { lng: e.latlng.lng, lat: e.latlng.lat }
            let data = Object.assign({}, that.state.condition, { markers: arr })
            that.setState({
                condition: data,
            })
        }
    }
    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, content: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.url })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    render() {

        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EditOutlined /> 编辑</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='huangse' icon={<PlusOutlined />}>发布活动</Button>
                }
                <Modal title="详情" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={[20, 0]} justify="start">
                            <Col className='gutter-row' span={24}>
                                <Form.Item
                                    label="封面图"
                                    tooltip="图片尺寸1000*600"
                                >
                                    <Upload
                                        showUploadList={false}
                                        listType="picture-card"
                                        action="/Ajax/uploadimages"
                                        onChange={this.picChange.bind(this, 'fmt')}
                                        multiple={false}
                                        style={{ width: 102 }}
                                        data={{ w: 1000, h: 600 }}
                                    >
                                        {this.state.condition.fmt ? (
                                            <img
                                                src={this.state.condition.fmt}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        ) :
                                            <PlusOutlined />
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="所属公司"
                                >
                                    <Select
                                        placeholder="请选择"
                                        style={{ width: "100%" }}
                                        value={this.state.condition.cid ? this.state.condition.cid : null}
                                        onChange={this.onSelChange.bind(this, 'cid')}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp="children"
                                        showSearch
                                    >
                                        {this.arrsel(this.state.company_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="活动标题"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.title ? this.state.condition.title : null} onChange={this.onChange.bind(this, 'title')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="活动内容"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.content ? this.state.condition.content : null} onChange={this.onChange.bind(this, 'content')} autoComplete="off" rows={6} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="活动开始时间"
                                >
                                    <DatePicker className="input" placeholder="请选择" onChange={this.onTimeChange.bind(this, 'starttime')} value={this.state.condition.starttime ? moment(this.state.condition.starttime) : null} style={{ width: "100%" }} showTime />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="活动结束时间"
                                >
                                    <DatePicker className="input" placeholder="请选择" onChange={this.onTimeChange.bind(this, 'endtime')} value={this.state.condition.endtime ? moment(this.state.condition.endtime) : null} style={{ width: "100%" }} showTime />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="报名截止时间"
                                >
                                    <DatePicker className="input" placeholder="请选择" onChange={this.onTimeChange.bind(this, 'dateline')} value={this.state.condition.dateline ? moment(this.state.condition.dateline) : null} style={{ width: "100%" }} showTime />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="活动地点"
                                >
                                    <Input.Group compact>
                                        <Input placeholder="省" value={this.state.condition.dd1 ? this.state.condition.dd1 : null} onChange={this.onChange.bind(this, 'dd1')} autoComplete="off" style={{ width: "calc(100% / 3)" }} />
                                        <Input placeholder="市" value={this.state.condition.dd2 ? this.state.condition.dd2 : null} onChange={this.onChange.bind(this, 'dd2')} autoComplete="off" style={{ width: "calc(100% / 3)" }} />
                                        <Input placeholder="区" value={this.state.condition.dd3 ? this.state.condition.dd3 : null} onChange={this.onChange.bind(this, 'dd3')} autoComplete="off" style={{ width: "calc(100% / 3)" }} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="活动地址"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.address ? this.state.condition.address : null} onChange={this.onChange.bind(this, 'address')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="报名人数"
                                >
                                    <Input placeholder="请输入" type='number' min={0} value={this.state.condition.bmrs ? this.state.condition.bmrs : null} onChange={this.onChange.bind(this, 'bmrs')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Radio.Group value={this.state.condition.status ? this.state.condition.status : null} onChange={this.onChange.bind(this, 'status')} >
                                        <Space wrap>
                                            {this.arrradio(this.state.status_react)}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {this.state.condition.status == 2 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="拒绝原因"
                                    >
                                        <TextArea placeholder="请输入" value={this.state.condition.reason ? this.state.condition.reason : null} onChange={this.onChange.bind(this, 'reason')} autoComplete="off" rows={6} />
                                    </Form.Item>
                                </Col>
                                : null}
                        </Row>

                        <Row justify="center">
                            <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}