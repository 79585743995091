import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { CheckableTag } = Tag;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                tags: [],
                pic: []
            },
            status_react: [
                { id: 1, name: '通过' },
                { id: 2, name: '拒绝' },
            ],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == "company") {
            // 公司和部门联动
            fetch('User/getBumen', {
                method: "POST",
                body: JSON.stringify({
                    company: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    // console.log(data);return
                    let data2 = Object.assign({}, this.state.condition, { bumen: null })
                    that.setState({
                        condition: data2,
                        bumen_react: data.data
                    })
                })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Xuqiu/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    that.setState({
                        tags_react: data.tags_react ? data.tags_react : [],
                        company_react: data.company_react ? data.company_react : [],
                    })
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    tags: [],
                    pic: []
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Xuqiu/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response.url)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        console.log(fjlist)
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, content: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    handleChange = (tag, name, checked) => {
        console.log(tag, checked)
        let selectedTags = this.state.condition.tags.concat()
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        // console.log('You are interested in: ', nextSelectedTags);
        if (nextSelectedTags.length > 3) {
            message.error('最多只能选3个')
            return
        }
        let data = Object.assign({}, this.state.condition, { tags: nextSelectedTags })
        this.setState({
            condition: data
        })
    };
    arrCheckableTag = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <CheckableTag
                    key={index}
                    checked={this.state.condition.tags.includes(item.id)}
                    onChange={this.handleChange.bind(this, item.id, 'tags')}
                >
                    {item.name}
                </CheckableTag>
            )
        }
    }

    render() {
        //多媒体上传
        const myUploadFn = (param) => {

            const serverURL = '/Ajax/uploads'
            const xhr = new XMLHttpRequest
            const fd = new FormData()

            const successFn = (response) => {
                // 假设服务端直接返回文件上传后的地址
                // 上传成功后调用param.success并传入上传后的文件地址
                let reg = /["\\]/g
                var url = xhr.responseText.replace(reg, "");
                param.success({
                    // url: xhr.responseText,
                    url: url,
                    meta: {
                        // id: 'xxx',
                        // title: 'xxx',
                        // alt: 'xxx',
                        loop: false, // 指定音视频是否循环播放
                        autoPlay: false, // 指定音视频是否自动播放
                        controls: true, // 指定音视频是否显示控制栏
                        poster: '', // 指定视频播放器的封面
                    }
                })
            }

            const progressFn = (event) => {
                // 上传进度发生变化时调用param.progress
                param.progress(event.loaded / event.total * 100)
            }

            const errorFn = (response) => {
                // 上传发生错误时调用param.error
                param.error({
                    msg: 'unable to upload.'
                })
            }

            xhr.upload.addEventListener("progress", progressFn, false)
            xhr.addEventListener("load", successFn, false)
            xhr.addEventListener("error", errorFn, false)
            xhr.addEventListener("abort", errorFn, false)

            fd.append('file', param.file)
            xhr.open('POST', serverURL, true)
            xhr.send(fd)

        }
        const controls = [
            'undo', 'redo', 'separator',
            'font-size', 'line-height', 'letter-spacing', 'separator',
            'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
            'superscript', 'subscript', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
            'headings', 'list-ul', 'list-ol', 'code', 'blockquote', 'table', 'media', 'separator',
            'hr', 'link', 'separator', 'separator',
            'clear'
        ]
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EditOutlined /> 编辑</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='huangse' icon={<PlusOutlined />}>添加需求</Button>
                }
                <Modal title="详情" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={[20, 0]} justify="start">
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="需求标题"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.title ? this.state.condition.title : null} onChange={this.onChange.bind(this, 'title')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="所属公司"
                                >
                                    <Select
                                        placeholder="请选择"
                                        style={{ width: "100%" }}
                                        value={this.state.condition.cid ? this.state.condition.cid : null}
                                        onChange={this.onSelChange.bind(this, 'cid')}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp="children"
                                        showSearch
                                    >
                                        {this.arrsel(this.state.company_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="需求标签"
                                >
                                    <Space wrap>
                                        {this.arrCheckableTag(this.state.tags_react)}
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="需求内容"
                                >
                                    {/* <div className="editor-wrapper" style={{ border: '1px solid #bbb' }}>
                                        <BraftEditor
                                            value={this.state.condition.editorState}
                                            onChange={this.editorChange}
                                            controls={controls}
                                            media={{ uploadFn: myUploadFn }}
                                        />
                                    </div> */}
                                    <TextArea placeholder="请输入" value={this.state.condition.content ? this.state.condition.content : null} onChange={this.onChange.bind(this, 'content')} autoComplete="off" rows={6} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                label="图片上传"
                                tooltip="最多上传9张图片"
                            >
                                <Row justify="space-between">
                                    <Space align="start">
                                        {this.state.condition.pic.length < 9 ?
                                            <Upload
                                                showUploadList={false}
                                                action="/Ajax/uploadimages"
                                                listType="picture-card"
                                                onChange={this.picChange2.bind(this, 'pic')}
                                                multiple={false}
                                            >
                                                <PlusOutlined />
                                            </Upload>
                                            : null}
                                        <Space wrap>
                                            {this.state.condition.pic ? this.state.condition.pic.map((item, index) =>
                                                <div className='picItem'>
                                                    <Image
                                                        width={100}
                                                        height={100}
                                                        src={item}
                                                    />
                                                    <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'pic')} />
                                                </div>
                                            ) : null}

                                        </Space>
                                    </Space>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label="有效期"
                            >
                                <DatePicker className="input" placeholder="请选择" onChange={this.onTimeChange.bind(this, 'dateline')} value={this.state.condition.dateline ? moment(this.state.condition.dateline) : null} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item
                                label="状态"
                            >
                                <Radio.Group value={this.state.condition.status ? this.state.condition.status : null} onChange={this.onChange.bind(this, 'status')} >
                                    <Space wrap>
                                        {this.arrradio(this.state.status_react)}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {this.state.condition.status == 2 ?
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="拒绝原因"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.reason ? this.state.condition.reason : null} onChange={this.onChange.bind(this, 'reason')} autoComplete="off" rows={6} />
                                </Form.Item>
                            </Col>
                            : null}
                        <Row justify="center">
                            <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                        </Row>
                    </Form>
                </Modal>
            </>
        )
    }
}