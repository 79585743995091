import React from 'react';
import { Tag, Row, Space, Button, Input, DatePicker, Select, Table, Pagination, Checkbox, Modal, Upload, Radio, Transfer, Form, Col, Rate, Divider,message } from 'antd';
import { Redirect, Link, withRouter } from 'react-router-dom';
import store from "../store/store.js";
import { AimOutlined, AlertOutlined, ApartmentOutlined, AppstoreAddOutlined, AppstoreOutlined,PlusSquareOutlined, PlusOutlined } from '@ant-design/icons'

const { Option, OptGroup } = Select;
export default class shouye extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sel: 1,
			condition: {
				tododata: [],
				renwulist: [],
				count: [],
				rankdata: [],
				type_react: []
			},
			condition2: {
				menu_list: [],
			},
		};
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			// console.log(store.getState().data.isLogin)
		})
	}
	componentWillMount() {
	}
	componentDidMount() {
		this.props.NAV_CHANGE('/', ['导航'])
		this.getdata()
	}
	getdata(e) {
		return
		// console.log(subtype)
		let that = this
		fetch('/Shouye/count', {
			// post提交
			method: "POST",
			body: JSON.stringify({
				sel: that.state.sel
			})
		})
			.then(res => res.json())
			.then(data => {
				console.log(data)
				that.setState({
					condition: data
				})
			})
	}

	render() {

		return (
			<></>
		)
	}
}

