import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Avatar, Popconfirm } from 'antd';
import store from "../store/store.js";
import { FormOutlined, UserOutlined, SearchOutlined, ReloadOutlined,DownloadOutlined,DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
import Edit from './edit.js';
import Plnum from './plnum.js';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.props.NAV_CHANGE('/dtgl', ['首页', '动态管理'])
        this.getdata(1)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
        
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    arrtag(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Tag color='blue' key={index}>{item}</Tag>
            )
        }
    }
    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Dongtai/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                that.setState({
                    list: data.datalist,
                    tableloading: false,
                    count: Number(data.count),
                    status_react: data.status_react?data.status_react:[],
                })

            })
    }
    delSel(edit, e) {
        let that = this
        fetch('/Dongtai/allsave', {
            method: "POST",
            body: JSON.stringify({
                'choose': this.state.selectedRowKeys,
                'edit': edit
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })

            })
    }
    download(type, e) {
        // type==1 下载选中部分
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        if (type == 1) {
            arr = {
                id: that.state.selectedRowKeys
            }
        }
        arr.download = 1
        // console.log(arr)
        fetch('/Dongtai/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })

            })
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '动态内容',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: '所属公司',
                dataIndex: 'cname',
                key: 'cname',
                width: 200,
            },
            {
                title: '评论数',
                dataIndex: 'plnum',
                key: 'plnum',
                width: 120,
                render: (row, record) => {
                    return (
                        <>
                            <Plnum num={row} type="1" id={record.id} />
                        </>
                    )
                },
            },
            {
                title: '点赞数',
                dataIndex: 'dznum',
                key: 'dznum',
                width: 120,
                render: (row, record) => {
                    return (
                        <>
                            <Plnum num={row} type="2" id={record.id} />
                        </>
                    )
                },
            },
            {
                title: '发布时间',
                dataIndex: 'addtime',
                key: 'addtime',
                width: 150,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 120,
                render: (row, record) => {
                    return (
                        <>
                            {row == 0 ? <Tag>{record.status_text}</Tag> : null}
                            {row == 1 ? <Tag color='green'>{record.status_text}</Tag> : null}
                            {row == 2 ? <Tag color='red'>{record.status_text}</Tag> : null}
                        </>
                    )
                },
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 120,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Edit refresh={this.getdata.bind(this)} id={record.id} />
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll:true,
            preserveSelectedRowKeys: false,
            // renderCell: function(checked, record, index, originNode){
            //     if(record.status!=1){
            //         return originNode
            //     }else{
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Row justify="space-between" style={{ marginBottom: 20 }}>
                    <Space wrap>
                        <Input className="input" placeholder="企业名称/关键词" value={this.state.newcondition.keywords ? this.state.newcondition.keywords : null} onChange={this.onChange.bind(this, 'keywords')} autoComplete="off" />
                        <Select
                            className='select' style={{ width: 160 }} placeholder="状态" value={this.state.newcondition.status ? this.state.newcondition.status : null} onChange={this.onSelChange.bind(this, 'status')} allowClear>
                            {this.arrsel(this.state.status_react)}
                        </Select>
                        <Button type="primary" icon={<SearchOutlined />} onClick={this.getdata.bind(this, 1)}>查询</Button>
                        <Button type="primary" icon={<ReloadOutlined />} className='huise' onClick={this.reset.bind(this)}>重置</Button>
                    </Space>
                    <Space wrap>
                        <Edit refresh={this.getdata.bind(this)} />
                        <Button type="primary" className='lvse' icon={<DownloadOutlined />} onClick={this.download.bind(this,0)}  >批量导出</Button>
                    </Space>
                </Row>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    // scroll={{x:2200}}
                    className='table'
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    <Popconfirm
                                        title="确认删除?"
                                        onConfirm={this.delSel.bind(this, 1)}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button type="primary" icon={<DeleteOutlined />} danger>删除</Button>
                                    </Popconfirm>
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
