//reducer

import { act } from "react-dom/test-utils";

const data = {
	isLogin: false,
	info: '',
	nav: '/',
	nav2: '',
	navname: '',
	opennav: '/',
	ismobile: '',
	xmtype: 0,
	zttype: 0,
	dwtype: 0,
	rmtype: 0,
	xstype: 0,
	refresh: null,
	jiaobiao:{},
	webinfo:{}
}
function IndexRedux(state = data, action) {
	// console.log(action.nav, action.navname);
	switch (action.type) {
		case "GO_LOGIN":
			//登录
			state.isLogin = true;
			state.info = action.info;
			state.nav = '/';
			return Object.assign({}, state, {})
		case "OUT_LOGIN":
			//退出登录
			state.isLogin = false;
			state.info = '';
			state.nav = '';
			state.navname = '';
			return Object.assign({}, state, {})
		case "GET_INFO":
		//获取登陆信息
		// fetch('/Adminbq/Login/checklogin', {
		// 	method: "POST",
		// })
		// .then(res => res.json())
		// .then(data => {
		// 	// console.log(data)
		// 	if (data.state==0) {
		// 		state.info = data;
		// 	}else{
		// 		state.info = '';
		// 		state.isLogin=false
		// 	}
		// 	return Object.assign({},state,{})
		// })
		case "NAV_CHANGE":
			state.nav = action.nav;
			state.navname = action.navname;
			state.opennav = action.opennav;
			return Object.assign({}, state, {})
		case "NAV_CHANGE2":
			state.nav2 = action.nav2;
			return Object.assign({}, state, {})
		case "IS_MOBILE":
			var sUserAgent = navigator.userAgent.toLowerCase();
			if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
				//移动端页面
				// console.log(111);
				state.ismobile = true;
			} else {
				//pc端页面
				// console.log(222);
				state.ismobile = false;
			}
			return Object.assign({}, state, {})
		case "SET_INFO":
			state.info = action.info;
			return Object.assign({}, state, {})
		case "SET_WEBINFO":
			state.webinfo = action.webinfo;
			return Object.assign({}, state, {})
		case "SET_XMTYPE":
			state.xmtype = action.xmtype;
			return Object.assign({}, state, {})
		case "SET_ZTTYPE":
			state.zttype = action.zttype;
			return Object.assign({}, state, {})
		case "SET_DWTYPE":
			state.dwtype = action.dwtype;
			return Object.assign({}, state, {})
		case "SET_RMTYPE":
			state.rmtype = action.rmtype;
			return Object.assign({}, state, {})
		case "SET_XSTYPE":
			state.xstype = action.xstype;
			return Object.assign({}, state, {})
		case "GET_REFRESH":
			state.refresh = action.refresh;
			return Object.assign({}, state, {})
		case "GET_JIAOBIAO":
			// console.log("####变化前####",state.jiaobiao)
			for (let key in action.jiaobiao) {
				state.jiaobiao[key]=action.jiaobiao[key]
			};
			// console.log("####变化后####",state.jiaobiao)
			return Object.assign({}, state, {})
		default:
			return state
	}
}


export default IndexRedux