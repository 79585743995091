import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image, Avatar } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            condition2: {

            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    getdata = () => {
        let that = this
        fetch("/Dongtai/pinglun", {
            method: "POST",
            body: JSON.stringify({
                id: this.state.condition.id,
                type: that.props.type
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                that.setState({
                    condition2: data.data,
                    list: data.datalist,
                })
            })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            that.getdata()
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                condition2: {

                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Dongtai/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        console.log(fjlist)
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, content: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    arrpic = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    arrpl = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index} >
                    <div className='a1'>
                        <div className='b1'>
                            <Space>
                                <div className='c1'>{item.username}</div>
                                <div className='c2'>{item.addtime}</div>
                            </Space>
                        </div>
                        <div className='b2'>
                            <Space>
                                <div className={`c ` + (item.status == 1 ? 'hover' : '')} onClick={this.dopinglun.bind(this, item.id, 1)} >显示</div>
                                <div className={`c ` + (item.status == 2 ? 'hover' : '')} onClick={this.dopinglun.bind(this, item.id, 2)}>隐藏</div>
                            </Space>
                        </div>
                    </div>
                    <div className='a2'>{item.content}</div>
                </div>
            )
        }
    }
    dopinglun = (id, status, e) => {
        let that = this
        fetch('/Dongtai/dopinglun', {
            method: "POST",
            body: JSON.stringify({
                id: id,
                status: status
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    that.getdata()
                } else {
                    message.error(data.msg)
                }
            })
    }
    arrdz = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index} >
                    <div className='a1'>
                        <Avatar size="large" icon={<UserOutlined />} src={item.tx} />
                    </div>
                    <div className='a2'>
                        <div className='b1'>{item.username}</div>
                        <div className='b2'>{item.addtime}</div>
                    </div>
                </div>
            )
        }
    }
    render() {

        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{this.props.num ? this.props.num : 0}</a>
                <Modal title={this.props.type == 1 ? '评论数' : '点赞数'} maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='dtview'>
                        <div className='p1'>{this.state.condition2.cname}</div>
                        <div className='p2'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.condition2.content }}></div>
                        </div>
                        <div className='p3'>
                            <Space wrap>
                                {this.state.condition2.pic ? this.state.condition2.pic.map((item, index) =>
                                    <div className='picItem'>
                                        <Image
                                            width={100}
                                            height={100}
                                            src={item}
                                        />
                                    </div>
                                ) : null}
                            </Space>
                        </div>
                        <div className='p4'>{this.props.type == 1 ? <>回复内容（{this.props.num ? this.props.num : 0}）</> : <>点赞（{this.props.num ? this.props.num : 0}）</>}：</div>
                        {this.props.type == 1 ?
                            <div className='p5'>
                                {this.arrpl(this.state.list)}
                            </div>
                            : null}
                        {this.props.type == 2 ?
                            <div className='p6'>
                                {this.arrdz(this.state.list)}
                            </div>
                            : null}
                    </div>
                </Modal>
            </>
        )
    }
}