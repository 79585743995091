import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Avatar, Popconfirm, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined, UserOutlined, SearchOutlined, ReloadOutlined,DeleteOutlined,EyeOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {},
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.props.NAV_CHANGE('/sjbf', ['首页', '数据备份'])
        this.getdata(1)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
        
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    arrtag(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Tag color='blue' key={index}>{item}</Tag>
            )
        }
    }
    getdata = (subtype = 0, e) => {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('/Database/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                })

            })
    }
    doview = () => {
        let that = this
        fetch('/Database/doview', {
            // post提交
            method: "POST",
            // body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }else{
                    message.success(data.msg);
                    that.getdata(0)
                }
            })
    }
    download(type, e) {
        // type==1 下载选中部分
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        if (type == 1) {
            arr = {
                id: that.state.selectedRowKeys
            }
        }
        arr.download = 1
        // console.log(arr)
        fetch('/Database/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg);
                    return
                }
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })

            })
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {},
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    render() {
        const columns = [
            {
                title: '备份时间',
                dataIndex: 'addtime',
                key: 'addtime',
                width: 180,
            },
            {
                title: '备份内容',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <a href={record.url} target='_blank'><EyeOutlined /> 下载</a>
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll:true,
            preserveSelectedRowKeys: false,
            // renderCell: function(checked, record, index, originNode){
            //     if(record.status!=1){
            //         return originNode
            //     }else{
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <Row justify="space-between" style={{ marginBottom: 20 }}>
                    <Space wrap>
                        <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'addtime')} value={[(this.state.newcondition.addtime1 ? moment(this.state.newcondition.addtime1) : null), (this.state.newcondition.addtime2 ? moment(this.state.newcondition.addtime2) : null)]} placeholder={['开始时间', '结束时间']} />
                        <Button type="primary" icon={<SearchOutlined />} onClick={this.getdata.bind(this, 1)}>查询</Button>
                        <Button type="primary" icon={<ReloadOutlined />} className='huise' onClick={this.reset.bind(this)}>重置</Button>
                    </Space>
                    <Space wrap>
                        <Popconfirm
                            title="确认备份?"
                            onConfirm={this.doview.bind(this)}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button type="primary">数据备份</Button>
                        </Popconfirm>
                    </Space>
                </Row>
                <Table
                    bordered
                    columns={columns}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    // scroll={{x:2200}}
                    className='table'
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {/* <Checkbox onChange={this.qxbox.bind(this)} checked={this.state.qx ? this.state.qx : false}>全选</Checkbox> */}
                            {hasSelected ?
                                <>
                                    {/* <Popconfirm
                                        title="确认删除?"
                                        onConfirm={this.delSel.bind(this, 1)}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        <Button type="primary" icon={<DeleteOutlined />} danger>删除</Button>
                                    </Popconfirm> */}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 10} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
