import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import gcoord from 'gcoord'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                markers: [{ lng: 116.40370134170176, lat: 39.89654448329967 }],
            },
            status_react: [
                { id: 1, name: '通过' },
                { id: 2, name: '拒绝' }
            ],
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == "company") {
            // 公司和部门联动
            fetch('User/getBumen', {
                method: "POST",
                body: JSON.stringify({
                    company: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    // console.log(data);return
                    let data2 = Object.assign({}, this.state.condition, { bumen: null })
                    that.setState({
                        condition: data2,
                        bumen_react: data.data
                    })
                })
        }
    }
    onSelLianDongChange(name, title, num, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (num == 1) {
            fetch('/Check/doprovince', {
                method: "POST",
                body: JSON.stringify({
                    province: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);return
                    let shuxing = title + (num + 1) + '_react'
                    let shuxing2 = title + (num + 2) + '_react'
                    console.log(shuxing)
                    that.setState({
                        [shuxing]: data,
                        [shuxing2]: []
                    })
                    let data2 = Object.assign({}, this.state.condition, { [title + (num + 1)]: null, [title + (num + 2)]: null })
                    this.setState({
                        condition: data2
                    })
                })
        } else if (num == 2) {
            let province = that.state.condition[title + (num - 1)]
            // console.log(province);return
            fetch('/Check/docity', {
                method: "POST",
                body: JSON.stringify({
                    province: province,
                    city: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);return
                    let shuxing = title + (num + 1) + '_react'
                    console.log(shuxing)
                    that.setState({
                        [shuxing]: data
                    })
                    let data2 = Object.assign({}, this.state.condition, { [title + (num + 1)]: null })
                    this.setState({
                        condition: data2
                    })
                })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Company/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    that.setState({
                        chy_react: data.chy_react ? data.chy_react : [],
                        xb_react: data.xb_react ? data.xb_react : [],
                        jrqx_react: data.jrqx_react ? data.jrqx_react : [],
                    })
                    if (this.state.condition.id) {
                        data.data.editorState = BraftEditor.createEditorState(data.data.czyyw)
                        if (data.data.longitude) {
                            var result = gcoord.transform(
                                [data.data.longitude, data.data.latitude],    // 经纬度坐标
                                gcoord.GCJ02,               // 当前坐标系
                                gcoord.BMap                 // 目标坐标系
                            );
                            data.data.markers[0]['lng'] = result[0]
                            data.data.markers[0]['lat'] = result[1]
                        }
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                    markers: [{ lng: 116.40370134170176, lat: 39.89654448329967 }],
                    editorState: BraftEditor.createEditorState(null)
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Company/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.url })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response.url)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        console.log(fjlist)
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    mapClick = (e) => {
        // console.log(e.latlng)
        let that = this
        // console.log(that.state.condition.markers)
        if (that.state.isbz) {
            // let arr = that.state.condition.markers.concat()
            // arr[0] = { lng: e.latlng.lng, lat: e.latlng.lat }
            var result = gcoord.transform(
                [e.latlng.lng, e.latlng.lat],    // 经纬度坐标
                gcoord.BMap,               // 当前坐标系
                gcoord.GCJ02                 // 目标坐标系
            );
            let data = Object.assign({}, that.state.condition, { mapx: e.latlng.lng, mapy: e.latlng.lat, longitude: result[0], latitude: result[1] })
            that.setState({
                condition: data,
            })
        }
    }
    changebz() {
        let bz = !this.state.isbz
        this.setState({
            isbz: bz
        })
    }
    searchMap = () => {
        let that = this
        fetch('/Company/adddresToCoordinates', {
            method: "POST",
            body: JSON.stringify({
                address: this.state.condition.mapkeywords
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data.data)

                if (data.status == 1) {

                    var arr = that.state.condition.markers.concat()
                    arr[0] = data.data
                    // arr[1]['lng']=data.data.lng
                    // arr[1]['lat']=data.data.lat
                    console.log(arr)
                    var data = Object.assign({}, that.state.condition, { markers: arr, mapx: data.data.lng, mapy: data.data.lat })
                    that.setState({
                        condition: data,
                    })
                } else {
                    message.error(data[0])
                }
            })
    }
    editorChange = (editorState) => {
        let data = Object.assign({}, this.state.condition, { editorState: editorState, content: editorState.toHTML() })
        this.setState({
            condition: data
        })
    }
    render() {
        //多媒体上传
        const myUploadFn = (param) => {

            const serverURL = '/Ajax/uploads'
            const xhr = new XMLHttpRequest
            const fd = new FormData()

            const successFn = (response) => {
                // 假设服务端直接返回文件上传后的地址
                // 上传成功后调用param.success并传入上传后的文件地址
                let reg = /["\\]/g
                var url = xhr.responseText.replace(reg, "");
                param.success({
                    // url: xhr.responseText,
                    url: url,
                    meta: {
                        // id: 'xxx',
                        // title: 'xxx',
                        // alt: 'xxx',
                        loop: false, // 指定音视频是否循环播放
                        autoPlay: false, // 指定音视频是否自动播放
                        controls: true, // 指定音视频是否显示控制栏
                        poster: '', // 指定视频播放器的封面
                    }
                })
            }

            const progressFn = (event) => {
                // 上传进度发生变化时调用param.progress
                param.progress(event.loaded / event.total * 100)
            }

            const errorFn = (response) => {
                // 上传发生错误时调用param.error
                param.error({
                    msg: 'unable to upload.'
                })
            }

            xhr.upload.addEventListener("progress", progressFn, false)
            xhr.addEventListener("load", successFn, false)
            xhr.addEventListener("error", errorFn, false)
            xhr.addEventListener("abort", errorFn, false)

            fd.append('file', param.file)
            xhr.open('POST', serverURL, true)
            xhr.send(fd)

        }
        const controls = [
            'undo', 'redo', 'separator',
            'font-size', 'line-height', 'letter-spacing', 'separator',
            'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
            'superscript', 'subscript', 'remove-styles', 'separator', 'text-indent', 'text-align', 'separator',
            'headings', 'list-ul', 'list-ol', 'code', 'blockquote', 'table', 'media', 'separator',
            'hr', 'link', 'separator', 'separator',
            'clear'
        ]
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EditOutlined /> 编辑</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='huangse' icon={<PlusOutlined />}>添加企业</Button>
                }
                <Modal title="详情" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 3 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                    >
                        <Row gutter={[20, 0]} justify="start">
                            <Col className='gutter-row' span={24}>
                                <Form.Item
                                    label="头像"
                                    tooltip="图片尺寸300*300"
                                >
                                    <Upload
                                        showUploadList={false}
                                        listType="picture-card"
                                        action="/Ajax/uploadimages"
                                        onChange={this.picChange.bind(this, 'tx')}
                                        multiple={false}
                                        style={{ width: 102 }}
                                        data={{ w: 300, h: 300 }}
                                    >
                                        {this.state.condition.tx ? (
                                            <img
                                                src={this.state.condition.tx}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        ) :
                                            <PlusOutlined />
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="企业名称"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cname ? this.state.condition.cname : null} onChange={this.onChange.bind(this, 'cname')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="所属行业"
                                >
                                    <Select
                                        placeholder="请选择"
                                        style={{ width: "100%" }}
                                        value={this.state.condition.chy ? this.state.condition.chy : null}
                                        onChange={this.onSelChange.bind(this, 'chy')}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp="children"
                                        showSearch
                                    >
                                        {this.arrsel(this.state.chy_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="姓名"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.xm ? this.state.condition.xm : null} onChange={this.onChange.bind(this, 'xm')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="性别"
                                >
                                    <Select
                                        placeholder="请选择"
                                        style={{ width: "100%" }}
                                        value={this.state.condition.xb ? this.state.condition.xb : null}
                                        onChange={this.onSelChange.bind(this, 'xb')}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp="children"
                                        showSearch
                                    >
                                        {this.arrsel(this.state.xb_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="手机号"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.sjh ? this.state.condition.sjh : null} onChange={this.onChange.bind(this, 'sjh')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="籍贯"
                                >
                                    <Input.Group compact>
                                        <Input placeholder="省" value={this.state.condition.jg1 ? this.state.condition.jg1 : null} onChange={this.onChange.bind(this, 'jg1')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                        <Input placeholder="市" value={this.state.condition.jg2 ? this.state.condition.jg2 : null} onChange={this.onChange.bind(this, 'jg2')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                        <Input placeholder="区" value={this.state.condition.jg3 ? this.state.condition.jg3 : null} onChange={this.onChange.bind(this, 'jg3')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="推荐人（选填）"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.tjr ? this.state.condition.tjr : null} onChange={this.onChange.bind(this, 'tjr')} autoComplete="off" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="加入期限"
                                >
                                    <Select
                                        placeholder="请选择"
                                        style={{ width: "100%" }}
                                        value={this.state.condition.jrqx ? this.state.condition.jrqx : null}
                                        onChange={this.onSelChange.bind(this, 'jrqx')}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        optionFilterProp="children"
                                        showSearch
                                    >
                                        {this.arrsel(this.state.jrqx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="地址"
                                >
                                    <Input.Group compact>
                                        <Input placeholder="省" value={this.state.condition.cdz1 ? this.state.condition.cdz1 : null} onChange={this.onChange.bind(this, 'cdz1')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                        <Input placeholder="市" value={this.state.condition.cdz2 ? this.state.condition.cdz2 : null} onChange={this.onChange.bind(this, 'cdz2')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                        <Input placeholder="区" value={this.state.condition.cdz3 ? this.state.condition.cdz3 : null} onChange={this.onChange.bind(this, 'cdz3')} autoComplete="off" style={{width:"calc(100% / 3)"}} />
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="详细地址"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.caddress ? this.state.condition.caddress : null} onChange={this.onChange.bind(this, 'caddress')} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={24}>
                                <Form.Item
                                    label="封面图"
                                    tooltip="图片尺寸1000*600"
                                >
                                    <Upload
                                        showUploadList={false}
                                        listType="picture-card"
                                        action="/Ajax/uploadimages"
                                        onChange={this.picChange.bind(this, 'cfmt')}
                                        multiple={false}
                                        style={{ width: 102 }}
                                        data={{ w: 1000, h: 600 }}
                                    >
                                        {this.state.condition.cfmt ? (
                                            <img
                                                src={this.state.condition.cfmt}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        ) :
                                            <PlusOutlined />
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="主营业务"
                                >
                                    <TextArea placeholder="请输入" value={this.state.condition.czyyw ? this.state.condition.czyyw : null} onChange={this.onChange.bind(this, 'czyyw')} autoComplete="off" rows={6} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="地图标注"
                                >
                                    <Space style={{ marginBottom: 20 }}>
                                        {/* <Input placeholder="输入城市名" value={this.state.condition.csm ? this.state.condition.csm : null} onChange={this.onChange.bind(this, 'csm')} /> */}
                                        <Input placeholder="输入地址" value={this.state.condition.mapkeywords ? this.state.condition.mapkeywords : null} onChange={this.onChange.bind(this, 'mapkeywords')} />
                                        <Button type='primary' onClick={this.searchMap.bind(this)} >搜索地点</Button>
                                    </Space>
                                    <Map center={{ lng: this.state.condition.markers[0] ? this.state.condition.markers[0]['lng'] : 0, lat: this.state.condition.markers[0] ? this.state.condition.markers[0]['lat'] : 0 }} zoom="8" onClick={this.mapClick.bind(this)} style={{ height: 500, marginBottom: 20 }} enableScrollWheelZoom={true}>
                                        <NavigationControl />
                                        <ZoomControl />
                                        <Marker position={{ lng: this.state.condition.markers[0] ? this.state.condition.markers[0]['lng'] : 0, lat: this.state.condition.markers[0] ? this.state.condition.markers[0]['lat'] : 0 }} />
                                        {this.state.condition.mapx && this.state.condition.mapy ?
                                            <Marker position={{ lng: this.state.condition.mapx, lat: this.state.condition.mapy }} />
                                            :
                                            null}
                                    </Map>
                                    <Space style={{ marginBottom: 20 }}>
                                        <Button type='primary' onClick={this.changebz.bind(this)} danger={this.state.isbz}>{this.state.isbz ? '停止标注' : '开始标注'}</Button>
                                        <Input placeholder="X" value={this.state.condition.longitude ? this.state.condition.longitude : 0} onChange={this.onChange.bind(this, 'longitude')} readOnly={true} />
                                        <Input placeholder="Y" value={this.state.condition.latitude ? this.state.condition.latitude : 0} onChange={this.onChange.bind(this, 'latitude')} readOnly={true} />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="公司图片"
                                >
                                    <Row justify="space-between">
                                        <Space align="start">
                                            <Upload
                                                showUploadList={false}
                                                action="/Ajax/uploadimages"
                                                listType="picture-card"
                                                onChange={this.picChange2.bind(this, 'ctp')}
                                                multiple={true}
                                            >
                                                <PlusOutlined />
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.ctp ? this.state.condition.ctp.map((item, index) =>
                                                    <div className='picItem'>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={item}
                                                        />
                                                        <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'ctp')} />
                                                    </div>
                                                ) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Radio.Group value={this.state.condition.status ? this.state.condition.status : null} onChange={this.onChange.bind(this, 'status')} >
                                        <Space wrap>
                                            {this.arrradio(this.state.status_react)}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {this.state.condition.status == 2 ?
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        label="拒绝原因"
                                    >
                                        <TextArea placeholder="请输入" value={this.state.condition.reason ? this.state.condition.reason : null} onChange={this.onChange.bind(this, 'reason')} autoComplete="off" rows={6} />
                                    </Form.Item>
                                </Col>
                                : null}
                        </Row>

                        <Row justify="center">
                            <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                        </Row>
                    </Form>
                </Modal >
            </>
        )
    }
}