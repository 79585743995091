import React from 'react'
import ReactDOM from 'react-dom';
import bj from '../images/bj.png'
import { Layout, Form, Input, Button, message,Space } from 'antd'
import { UserOutlined, LockOutlined,VerifiedOutlined,CheckCircleFilled } from '@ant-design/icons'
import PropTypes from 'prop-types';
import ReactSimpleVerify from 'react-simple-verify'
import 'react-simple-verify/dist/react-simple-verify.css'
import { Redirect } from 'react-router'
import store from "../store/store.js";

class Formlogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title:props.title,
			isLogin: store.getState().data.isLogin,
			yz: false,
			verifyimg:'/login/verify'
		}
		
	}
	componentDidMount() {
		let that = this
		that.getVerify()
	}
	UNSAFE_componentWillReceiveProps(nextProps){
		this.setState({
			title:nextProps.title
		})
	}
	onFinish(values) {
		// if (this.state.yz) {
			fetch('/Login/dologin', {
				// post提交
				method: "POST",
				body: JSON.stringify(values)
			})
				.then(res => res.json())
				.then(data => {
					console.log(data)
					if (data.status == 1) {
						message.success(data.msg);
						this.props.doLoginRedux.GOLOGIN(data.data, this.props.doLoginRedux.history);
						this.setState({ isLogin: store.getState().data.isLogin })
					} else {
						message.error(data.msg);
					}
				})
		// } else {
		// 	message.error('请验证后登录');
		// }
	};
	success() {
		this.setState({
			yz: true
		})
	}
	islogin() {
		if (this.state.isLogin == true) {
			return <Redirect to={'/'} />;
		}
	}
	getVerify = () =>{
		let that = this
		that.setState({
			verifyimg:"/login/verify.html"+"?"+Math.random()
		})
	}
	
	render() {
		{ this.islogin() }
		return (
			<>
				<div className='left'>
					{/* <div className='p'>ACCUMULATE PLATFORM</div>
					<div className='p'>INTELLIGENCE</div>
					<div className='p'>TO HELP</div>
					<div className='p'>INDUSTRIAL DEVELOPMENT</div>
					<div className='p'>智慧化的产业发展综合服务商</div>
					<div className='p2'>
						<Space size={[30,0]} wrap>
							<div>
								<CheckCircleFilled /> 产业招商
							</div>
							<div>
								<CheckCircleFilled /> 产业培育
							</div>
							<div>
								<CheckCircleFilled /> 产业升级
							</div>
						</Space>
					</div> */}
				</div>
				<div style={{ 'backgroundImage': `url(` + bj + `)` }} className="bj"></div>
				<div className="right">
					<div className='head'>产业协同发展综合平台</div>
					<Form
						name="normal_login"
						className="login-form"
						initialValues={{
							type: 2
						}}
						onFinish={this.onFinish.bind(this)}
					>
						<Form.Item
							name="username"
							rules={[
								{
									required: true,
									message: '请输入账号!',
								},
							]}
						>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" className="input" autoComplete="off" />
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: '请输入密码!',
								},
							]}
						>
							<Input
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="密码"
								className="input"
								autoComplete="off"
							/>
						</Form.Item>
						<Form.Item
							name="verify"
							rules={[
								{
									required: true,
									message: '请输入验证码!',
								},
							]}
						>
							<Space style={{width:'100%'}}>
								<Input prefix={<VerifiedOutlined className="site-form-item-icon" />} placeholder="验证码" className="input" autoComplete="off" style={{width:'100%'}} />
								{/* <img src={this.state.verifyimg} onClick={this.getVerify.bind(this)} width="120"></img> */}
								<img src={this.state.verifyimg} onClick={this.getVerify.bind(this)} style={{width:'100%',height:'100%'}}></img>
							</Space>
						</Form.Item>
						{/* <Form.Item
						>
							<ReactSimpleVerify ref="verify" success={this.success.bind(this)} width="360" height={60} />
						</Form.Item> */}
						<Form.Item>
							<Button htmlType="submit" className="login-form-button" style={{ fontSize: 18 }} >
								立即登录
							</Button>
						</Form.Item>
					</Form>
				</div>
				<div className='footer'>
					{/* Copyright © 2022 北京慧产科技有限公司版权所有  <a href='https://beian.miit.gov.cn/' target="_blank">京ICP备20018293号-3</a> */}
				</div>
			</>
		);
	}
};
export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title:''
		};
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			// console.log(store.getState().data)
			if (store.getState().data.webinfo) {
				// document.title = store.getState().data.webinfo.seo_title
				// document.getElementsByTagName("meta").keywords.content = store.getState().data.webinfo.seo_keyword
				// document.getElementsByTagName("meta").description.content = store.getState().data.webinfo.seo_description
				// this.setState({
				// 	title:store.getState().data.webinfo.seo_title
				// })
			}
		})
	}
	componentWillMount() {
		this.props.OUT_LOGIN();
		// this.getwebdata()
	}
	render() {
		return (
			<div style={{ 'backgroundColor': `#202d3e`, 'width': '100%', 'height': '100%', 'float': 'left' }} >
				<div className="loginpost">
					<Formlogin doLoginRedux={this.props} title={this.state.title} />
				</div>
			</div>
		)
	}
}
