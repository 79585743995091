import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image, Avatar } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            condition2:{},
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (name == "company") {
            // 公司和部门联动
            fetch('User/getBumen', {
                method: "POST",
                body: JSON.stringify({
                    company: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    // console.log(data);return
                    let data2 = Object.assign({}, this.state.condition, { bumen: null })
                    that.setState({
                        condition: data2,
                        bumen_react: data.data
                    })
                })
        }
    }
    onSelLianDongChange(name, title, num, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
        if (num == 1) {
            fetch('/Check/doprovince', {
                method: "POST",
                body: JSON.stringify({
                    province: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);return
                    let shuxing = title + (num + 1) + '_react'
                    let shuxing2 = title + (num + 2) + '_react'
                    console.log(shuxing)
                    that.setState({
                        [shuxing]: data,
                        [shuxing2]: []
                    })
                    let data2 = Object.assign({}, this.state.condition, { [title + (num + 1)]: null, [title + (num + 2)]: null })
                    this.setState({
                        condition: data2
                    })
                })
        } else if (num == 2) {
            let province = that.state.condition[title + (num - 1)]
            // console.log(province);return
            fetch('/Check/docity', {
                method: "POST",
                body: JSON.stringify({
                    province: province,
                    city: value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);return
                    let shuxing = title + (num + 1) + '_react'
                    console.log(shuxing)
                    that.setState({
                        [shuxing]: data
                    })
                    let data2 = Object.assign({}, this.state.condition, { [title + (num + 1)]: null })
                    this.setState({
                        condition: data2
                    })
                })
        }
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Huodong/baoming', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition2: data.data,
                            list: data.datalist,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                condition2: {

                },
                loading: false
            })
        }
    }


    arrCheckbox = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    arrpic = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            )
        }
    }
    arrbm = (arr) => {
        if (arr) {
            return arr.map((item, index) =>
                <div className='item' key={index} >
                    <div className='a1'>
                        <Avatar size="large" icon={<UserOutlined />} src={item.tx} />
                    </div>
                    <div className='a2'>
                        <div className='b1'>{item.username}</div>
                        <div className='b2'>{item.addtime}</div>
                    </div>
                </div>
            )
        }
    }
    render() {

        return (
            <>
                <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{this.props.num ? this.props.num : 0}</a>
                <Modal title="报名人数" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='dtview'>
                        <div className='p1'>{this.state.condition2.cname}</div>
                        <div className='p2'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.condition2.content }}></div>
                        </div>
                        <div className='p4'>报名人数（{this.props.num ? this.props.num : 0}）</div>
                        <div className='p6'>
                            {this.arrbm(this.state.list)}
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}