import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Layout, Menu, MenuProps, Breadcrumb, Avatar, message, Badge, Row, Space, Dropdown, Tooltip } from 'antd';
import { InsertRowRightOutlined, PoweroffOutlined, DashboardOutlined, MailOutlined, FileProtectOutlined, CloudServerOutlined, FlagOutlined, IdcardOutlined, ApartmentOutlined, BookOutlined, PlusSquareOutlined, CopyOutlined, UserOutlined, CommentOutlined, MessageOutlined, WechatOutlined, SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '../index.css';
import 'braft-extensions/dist/table.css'


import store from "../store/store.js";
moment.locale('zh-cn');
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
class SiderDemo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumb: store.getState().data.navname ? store.getState().data.navname : ['首页'],
			defaultnav: '1',
			current: '/',
			isLogin: store.getState().data.isLogin ? store.getState().data.isLogin : false,
			name: store.getState().data.info.name,
		}
		// props.GET_INFO();
		store.subscribe(() => {
			// console.log('state状态改变了，新状态如下')
			// console.log(store.getState().data.info.lmqx_ky)
			this.setState({
				islogin: store.getState().data.isLogin,
				breadcrumb: store.getState().data.navname,
			});
		})
	}
	onCollapse = collapsed => {
		this.setState({ collapsed });
	};
	breadcrumbItem = e => {
		let that = this
		if (this.state.breadcrumb) {
			// 	console.log(this.state.breadcrumb)
			return that.state.breadcrumb.map((item, index) =>

				<Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
			)
		}
	}
	componentWillReceiveProps(nextProps) {
		// console.log('路由变化了')
		// 判断跳转路由不等于当前路由
		if (nextProps.location.pathname !== this.props.location.pathname) {
			this.islogin()
			// console.log(store.getState().data)
		}
	}

	componentWillMount() {

	}
	islogin() {
		fetch('/Check/checklogin', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				// console.log(data);return
				if (data.state == 1) {
					this.props.SET_INFO(data.data)
					this.setState({
						isLogin: true,
						// name:data.name,
					})
					if (data.status != 1) {
						this.logout();
					}
				} else {
					this.props.OUT_LOGIN();
					// console.log(store.getState().data.isLogin );
					this.setState({
						isLogin: false
					})
				}
			})
	}
	componentDidMount() {
		// console.log(this.props.location.pathname)
		// let time = setInterval(() => {
		// this.islogin()
		// }, 5000);
		// this.setState({
		// time: time
		// })
		let that = this
		that.islogin()
		let time = setInterval(() => {
			that.islogin()
		}, 600000)
		that.setState({
			time: time
		})
	}
	componentWillUnmount() {
		clearInterval(this.state.time)
	}
	logout() {
		// this.props.history.push("/login")
		// message.success('退出登录');
		fetch('/Login/logout', {
			method: "POST",
		})
			.then(res => res.json())
			.then(data => {
				if (data.status == 1) {
					this.props.history.push("/login")
					message.success(data.msg);
					// this.props.OUT_LOGIN()
					// setTimeout(
					// 	() => {
					// 		this.setState({ isLogin: store.getState().data.isLogin })
					// 	}
					// 	, 1000);
				} else {
					this.setState({ isLogin: store.getState().data.isLogin })
				}
			})
	}
	nav_change2 = (nav, e) => {
		let that = this
		// console.log(nav, e);
		that.props.NAV_CHANGE2(nav)
	}
	render() {
		let isLogin = store.getState().data.isLogin ? store.getState().data.isLogin : false
		if (!isLogin) {
			return <Redirect to={'/login'} />;
		}
		const menu = (
			<Menu
				items={[
					{
						key: '1',
						label: (
							<Link to={{ pathname: '/geren' }} >个人资料</Link>
						),
						icon: <IdcardOutlined />
					},
					{
						key: '2',
						label: (
							<Link to={{ pathname: '/mmxg' }} >密码修改</Link>
						),
						icon: <PlusSquareOutlined />,
						// disabled: true,
					},
					{
						key: '3',
						label: (
							<Link to={{ pathname: '/znxx' }} >站内消息</Link>
						),
						icon: <FlagOutlined />
					}
				]}
			/>
		);
		return (
			<>
				{!isLogin ?
					null
					:
					<>
						<Layout>
							<Header className="header">
								<Row justify='space-between'>
									<div style={{ width: "calc(100% - 320px)" }}>
										<div className="logo" >产业协同发展综合平台</div>

									</div>
									<div className='butr' style={{ color: '#fff' }}>
										<Space size={15}>
											<Tooltip title="复制新页面">
												<a href={'#' + this.props.location.pathname} target="_blank" className='tubiao' >
													<CopyOutlined />
												</a>
											</Tooltip>
											{/* <Tooltip title="站内信">
												<Link className='tubiao' >
													<Badge count={store.getState().data.jiaobiao ? store.getState().data.jiaobiao.msgnum1 : 0} size="small" offset={[0, 0]} overflowCount={99999}>
														<MailOutlined />
													</Badge>
												</Link>
											</Tooltip> */}
											{/* <Dropdown overlay={menu} overlayStyle={{ width: 100 }}>
												<a onClick={(e) => e.preventDefault()} style={{ color: '#fff' }}>
													<Space>
														<Avatar size="small" icon={<UserOutlined />} src={store.getState().data.info.tx} />
														<span>{store.getState().data.info.name}</span>
													</Space>
												</a>
											</Dropdown> */}
											<Space>
												<Avatar size="small" icon={<UserOutlined />} src={store.getState().data.info.tx} />
												<span>{store.getState().data.info.name}</span>
											</Space>

											<PoweroffOutlined title='退出登录' onClick={this.logout.bind(this)} />
										</Space>
									</div>
								</Row>
							</Header>
							<Layout>
								<Sider width={200} theme="dark" >
									<Menu mode="inline" theme="dark" className='leftmenu' selectedKeys={[(store.getState().data.nav ? store.getState().data.nav : null)]} >
										<Menu.Item key="/" icon={<DashboardOutlined />} title="首页"  >
											<Link to={{ pathname: '/' }}  >
												<span>首页</span>
											</Link>
										</Menu.Item>
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('1') ?
											<Menu.Item key="/qygl" icon={<ApartmentOutlined />} title="企业管理"  >
												<Link to={{ pathname: '/qygl' }}  >
													<span>企业管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('2') ?
											<Menu.Item key="/xqgl" icon={<BookOutlined />} title="需求管理"  >
												<Link to={{ pathname: '/xqgl' }}  >
													<span>需求管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('3') ?
											<Menu.Item key="/dtgl" icon={<InsertRowRightOutlined />} title="动态管理"  >
												<Link to={{ pathname: '/dtgl' }}  >
													<span>动态管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('4') ?
											<Menu.Item key="/hdgl" icon={<FlagOutlined />} title="活动管理"  >
												<Link to={{ pathname: '/hdgl' }}  >
													<span>活动管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('5') ?
											<Menu.Item key="/plgl" icon={<CommentOutlined />} title="评论管理"  >
												<Link to={{ pathname: '/plgl' }}  >
													<span>评论管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('6') ?
											<Menu.Item key="/xxgl" icon={<MessageOutlined />} title="消息管理"  >
												<Link to={{ pathname: '/xxgl' }}  >
													<span>消息管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('7') ?
											<Menu.Item key="/wxyhgl" icon={<WechatOutlined />} title="微信用户管理"  >
												<Link to={{ pathname: '/wxyhgl' }}  >
													<span>微信用户管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('8') ?
											<Menu.Item key="/htzhgl" icon={<UserOutlined />} title="后台账号管理"  >
												<Link to={{ pathname: '/htzhgl' }}  >
													<span>后台账号管理</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('9') ?
											<Menu.Item key="/czjl" icon={<FileProtectOutlined />} title="操作记录"  >
												<Link to={{ pathname: '/czjl' }}  >
													<span>操作记录</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('10') ?
											<Menu.Item key="/sjbf" icon={<CloudServerOutlined />} title="数据备份"  >
												<Link to={{ pathname: '/sjbf' }}  >
													<span>数据备份</span>
												</Link>
											</Menu.Item>
											: null) : null}
										{store.getState().data.info.right ? (store.getState().data.info.right.includes('11') ?
											<Menu.Item key="/xtsz" icon={<SettingOutlined />} title="系统设置"  >
												<Link to={{ pathname: '/xtsz' }}  >
													<span>系统设置</span>
												</Link>
											</Menu.Item>
											: null) : null}
									</Menu>
								</Sider>
								<Layout style={{ padding: '0 24px 24px', height: "calc(100vh - 64px)", overflow: 'auto' }}>
									{(store.getState().data.nav != '/') ?
										<>
											<Breadcrumb style={{ margin: '16px 0' }} separator=">">
												{this.breadcrumbItem()}
											</Breadcrumb>
											<Content
												className="site-layout-background"
												style={
													(store.getState().data.nav2 == '/qjokr' || store.getState().data.nav2 == '/wdokr') ?
														{
															padding: 0,
															margin: 0,
															minHeight: 280,
															overflow: 'auto',
															backgroundColor: '#f0f2f5',
														}
														:
														{
															padding: 24,
															margin: 0,
															minHeight: 280,
															overflow: 'auto'
														}
												}
											>
												{this.props.children}
											</Content>
										</>
										:
										<div className="site-layout-background" style={{ backgroundColor: '#f0f2f5', height: 'calc(100vh - 80px)', paddingTop: 16, overflowY: 'auto' }}>
											{this.props.children}
										</div>
									}
								</Layout>
							</Layout>
						</Layout>
					</>
				}
			</>
		);
	}
}
export default withRouter(SiderDemo);