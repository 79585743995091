import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table as Table2, Modal, Row, Col, Form, message, Checkbox, Radio, DatePicker, Tag, Divider, Upload, Image } from 'antd';
import store from "../store/store.js";
import { FormOutlined, EditOutlined, PlusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import Table from 'braft-extensions/dist/table'
import { ContentUtils } from 'braft-utils'
import { ImageUtils } from 'braft-finder'
import moment from 'moment';
import { Map, Marker, NavigationControl, InfoWindow, ZoomControl } from 'react-bmapgl';
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const options = {
    defaultColumns: 3, // 默认列数
    defaultRows: 3, // 默认行数
    withDropdown: true, // 插入表格前是否弹出下拉菜单
    columnResizable: true, // 是否允许拖动调整列宽，默认false
    exportAttrString: 'border="1" style="border-collapse: collapse"', // 指定输出HTML时附加到table标签上的属性字符串
    // includeEditors: [], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
    // excludeEditors: []  // 指定该模块对哪些BraftEditor无效
}

BraftEditor.use(Table(options))
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
            },
            visible: false,
            loading: false,
            status_react: [
                { id: 1, name: '通过' },
                { id: 2, name: '拒绝' },
            ],
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            // if(this.state.condition.id){
            // return
            fetch('/Pinglun/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data)
                    if (data.status == 0) {
                        message.error(data.msg);
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                })
            // }
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Pinglun/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    render() {
        return (
            <>
                {this.state.condition.id ?
                    <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} ><EditOutlined /> 编辑</a>
                    :
                    <Button type="primary" onClick={this.mdchange.bind(this)} className='huangse' icon={<PlusOutlined />}>发布动态</Button>
                }
                <Modal title="编辑评论" maskClosable={true} destroyOnClose={true} width={800} visible={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <div className='dtview'>
                        <div className='p1'>{this.state.condition.cname}</div>
                        <div className='p2'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.condition.dtcontent }}></div>
                        </div>
                        <div className='p4'>评论内容</div>
                        <div className='p7'>
                            <div className='a1'>
                                <Space>
                                    <div className='b1'>{this.state.condition.uname}</div>
                                    <div className='b2'>{this.state.condition.addtime}</div>
                                </Space>
                            </div>
                            <div className='a2'>
                                <TextArea placeholder="请输入" value={this.state.condition.content ? this.state.condition.content : null} onChange={this.onChange.bind(this, 'content')} autoComplete="off" rows={6} />
                            </div>
                        </div>
                        <div className='p4'>
                            <Space size={[20, 0]}>
                                <span>审核管理</span>
                                <span style={{ fontWeight: 'normal' }}>
                                    <Radio.Group value={this.state.condition.status ? this.state.condition.status : null} onChange={this.onChange.bind(this, 'status')} >
                                        <Space wrap>
                                            {this.arrradio(this.state.status_react)}
                                        </Space>
                                    </Radio.Group>
                                </span>
                            </Space>
                        </div>
                        <div className='p4'>
                            {this.state.condition.status == 2 ?
                                <Space size={[20, 0]}>
                                    <span>拒绝原因</span>
                                    <div style={{ fontWeight: 'normal',width:668 }}>
                                        <TextArea placeholder="请输入" value={this.state.condition.reason ? this.state.condition.reason : null} onChange={this.onChange.bind(this, 'reason')} autoComplete="off" rows={6} />
                                    </div>
                                </Space>
                                : null}
                        </div>
                        <Row justify="center">
                            <Button type="primary" style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>提交</Button>
                        </Row>
                    </div>
                </Modal>
            </>
        )
    }
}